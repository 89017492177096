.svg{
    display: block;
}

.svg-circle {
    fill: none;
}

.svg-circle-text-primary {
    font-size: 25px;
    text-anchor: middle;
    fill: #FAC238;
    font-weight: 500;
    font-family: 'outfit';
}

.svg-circle-text-secondary {
    font-size: 15px;
    text-anchor: middle;
    fill: #fff;
    font-weight: 500;
    font-family: 'outfit';
}

.svg-circle-text-primary-2 {
    font-size: 15px;
    text-anchor: middle;
    fill: #06113F;
    font-weight: 400;
    font-family: 'outfit';
}

.svg-circle-text-secondary-2 {
    font-size: 40px;
    text-anchor: middle;
    fill: #06113F;
    font-weight: 500;
    font-family: 'outfit';
}